<template>
  <div class="pvz-header-block">
    <title-box tag="h3" class="pvz-header-block__title">
      Пункты выдачи
    </title-box>
    <search-metro
      v-if="showMetro"
      class="pvz-header-block__search-metro"
      :stations="stations"
      :is-loading="isLoading"
      @selected-metro="handlerSelectedMetro"
    />

    <div class="pvz-header-block__select-type-pvz">
      <pvz-select-type
        :pvz-list="pvzList"
        :pvz-types="pvzTypes"
        :active-pvz-code="activePvzCode"
        @select-pvz-type="handlerSelectPvzType"
      />
    </div>

    <filter-list
      class="pvz-header-block__filter-list"
      :is-loading="isLoading"
      :filter="filter"
      @selected-type="handlerSelectedType"
    />
  </div>
</template>

<script>
import TitleBox from '@/modules/checkout/components/_desktop/TitleBox';
import SearchMetro from '@/modules/checkout/components/_desktop/SearchMetro';
import FilterList from '@/modules/checkout/components/_desktop/FilterList';
import PvzSelectType from '@/components/PvzSelectType';

export default {
  name: 'HeaderBlockD',
  components: {
    PvzSelectType,
    TitleBox,
    SearchMetro,
    FilterList,
  },
  props: {
    stations: {
      type: Array,
      default: () => [],
    },
    showMetro: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    activePvzCode: {
      type: String,
      default: () => null,
    },
    pvzList: {
      type: Array,
      default: () => [],
    },
    pvzTypes: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handlerSelectedMetro(data) {
      this.$emit('selected-metro', data);
    },
    handlerSelectedType(data) {
      this.$emit('selected-type', data);
    },
    handlerSelectPvzType(data) {
      this.$emit('selected-pvz-type', data);
    },
  },
};
</script>

<style lang="less">
.pvz-header-block {
  &__title {
    margin-bottom: @size-x6;
  }

  &__search-metro,
  &__select-type-pvz {
    margin-bottom: @size-x5;
  }
}
</style>
