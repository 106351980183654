<template>
  <component
    :is="tag"
    class="title-box-checkout"
    :class="rootClass"
  >
    <slot></slot>
  </component>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TitleBox',
  props: {
    tag: {
      type: String,
      default: 'h2',
    },
    isContainer: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const rootClass = computed(() => ({
      '_no-container': !props.isContainer,
    }));

    return {
      rootClass,
    };
  },
});
</script>

<style lang="less">
.title-box-checkout {
  .h-reset();
  .h3-desktop();

  color: @black;
  font-weight: bold;

  h3& {
    .h4-desktop();
  }
}
</style>
