<template>
  <div class="filter-list-pvz">
    <span class="filter-list-pvz__tag-desc">Показать:</span>
    <checkbox-control
      v-for="item in filter"
      :key="item.code"
      v-model="item.value"
      class="filter-list-pvz__tag-item"
      :is-disabled="isLoading"
      @change="handlerChange(item)"
    >
      {{ item.title }}
    </checkbox-control>
  </div>
</template>

<script>
import { CheckboxControl } from '@book24/ui-components';

export default {
  name: 'FilterList',
  components: {
    CheckboxControl,
  },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handlerChange(item) {
      this.$emit('selected-type', { code: item.code, value: item.value });
    },
  },
};
</script>

<style lang="less">
.filter-list-pvz {
  display: flex;
  align-items: center;

  &__tag-item {
    margin-left: @size-x4;
  }

  &__tag-desc {
    .p();

    color: @gray-dark;
  }
}
</style>
