<template>
  <div class="checkout-modal-map">
    <div ref="list" class="checkout-modal-map__side-bar">
      <header-block
        class="checkout-modal-map__header"
        :show-metro="showMetro"
        :active-type="activeType"
        :stations="metroStations"
        :filter="filter"
        :is-loading="isLoading"
        :pvz-list="pvzData.list"
        :pvz-types="pvzTypes"
        :active-pvz-code="activePvzCode"
        @selected-metro="handlerSelectedMetro"
        @selected-type="handlerSelectedType"
        @selected-pvz-type="handlerSelectedPvzType"
      />
      <div ref="pvzList" class="checkout-modal-map__list">
        <pvz-list
          :is-desktop="true"
          :show-loader-block="false"
          :pvz-data="pvzData"
          :is-loading="isLoading"
          :show-error="showErrorNear"
          :text-error="textErrorNear"
          :history-pvz="historyPvz"
          :is-pay-online="isPayOnline"
          :active-pvz-code="activePvzCode"
          @select-pvz="handlerSelectPvz"
          @view-pvz="handlerViewPvz"
        />
      </div>
    </div>
    <div ref="mapBlock" class="checkout-modal-map__map">
      <pvz-map
        ref="map"
        :list="pvzData.list"
        :show-search="true"
        :show-loader-block="false"
        :is-loading="isLoading"
        :show-error="showErrorNear"
        :text-error="textErrorNear"
        :is-desktop="true"
        :center="cityCoords"
        :is-pay-online="isPayOnline"
        :is-russian="isRussian"
        :active-pvz-code="activePvzCode"
        @select-pvz="handlerSelectPvz"
        @show-description="showDescriptionHandler"
      />
    </div>
    <loader-block v-if="isLoading" text="" />
  </div>
</template>

<script>
import { LoaderBlock } from '@book24/ui-components';

import PvzListMixin from '@/modules/checkout/mixins/PvzListMixin';

import HeaderBlock from '@/modules/checkout/components/_desktop/HeaderBlockD';
import PvzMap from '@/components/PvzMap';
import PvzList from '@/components/PvzList';

export default {
  name: 'MapListPvz',
  components: {
    HeaderBlock,
    PvzMap,
    PvzList,
    LoaderBlock,
  },
  mixins: [PvzListMixin],
  mounted() {
    this.handlerOpened();
  },
  methods: {
    handlerOpened() {
      const modal = this.$vfm.modals.find(item => item.name === 'choice-pvz');

      if (modal) {
        const container = modal.$el.querySelector('.js-map-modal');
        const heightContainer = container.offsetHeight;
        if (container && heightContainer) {
          this.$refs.list.style.height = `${heightContainer}px`;
          this.$refs.mapBlock.style.height = `${heightContainer}px`;
        }
      }
    },
    handlerViewPvz(item) {
      this.$refs.map.openBalloon(item);
    },
  },
};
</script>

<style lang="less">
.checkout-modal-map {
  display: flex;
  align-items: stretch;
  overflow: hidden;
  position: relative;

  &__side-bar {
    flex: 0 0 450px;
    padding: @size-x6 @size-x6 @size-x5;
    display: flex;
    flex-direction: column;
  }

  &__list {
    overflow-y: auto;
  }

  &__map {
    width: 100%;
  }

  &__header {
    margin-bottom: @size-x2;
  }
}
</style>
