<template>
  <form-item class="pvz-metro-block">
    <autocomplete
      v-model="query"
      placeholder="Введите название станции метро"
      :suggestions-list="suggestion"
      :show-suggestions="showSuggests"
      :is-loading="isLoading"
      :is-disabled="isLoading"
      @clear="handlerClear"
      @click-outside="handlerClickOutside"
      @selected-item="handlerClickItem"
    >
      <template #prepend>
        <search-input16
          class="b24-input-control__control-icon"
          :heigth="16"
          :width="16"
        />
      </template>
    </autocomplete>
  </form-item>
</template>

<script>
import transform from 'lodash.transform';
import { FormItem, Autocomplete } from '@book24/ui-components';

import SearchInput16 from '@/assets/sprite/svg/16/Search_input_16.svg';

export default {
  name: 'SearchMetro',
  components: {
    FormItem,
    Autocomplete,
    SearchInput16,
  },
  props: {
    stations: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    suggestion: [],
    query: '',
    showSuggests: false,
    loading: false,
    limit: 10,
  }),
  watch: {
    query(value, oldValue) {
      if (value.length && value !== oldValue) {
        this.searchList();
      } else {
        this.showSuggests = false;
      }
    },
  },
  methods: {
    searchList() {
      const lowerQuery = this.query.toLowerCase();
      const names = [];

      const filteredList = transform(this.stations, (list, item, key) => {
        const lowerName = item.name.toLowerCase();
        const queryIncludes = lowerName.includes(lowerQuery);
        const notDuplicate = !names.includes(item.name);
        const isValid = lowerQuery && queryIncludes && notDuplicate;

        if (isValid) {
          names.push(item.name);
          list.push({
            title: item.name,
            id: key,
            coordinates: item.coordinates,
            metroId: item.metroId,
          });
        }

        return list;
      }, []);

      this.suggestion = filteredList.slice(0, this.limit - 1);

      this.showSuggests = this.suggestion.length > 0;
    },
    handlerClear() {
      this.suggestsList = [];
      this.showSuggests = false;
      this.$emit('selected-metro', []);
    },
    handlerClickOutside() {
      this.showSuggests = false;
    },
    handlerClickItem(item) {
      if (item) {
        this.query = item.title;
        this.$nextTick(() => {
          this.showSuggests = false;
        });
        this.$emit('selected-metro', item.coordinates);
      }
    },
  },
};
</script>
